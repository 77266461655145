
.stars {
    display: inline-block;

    .star {
        height: 15px;
        float: left;
        width: 15px;

        &--small {
            height: 13px;
            width: 13px;
        }

        &--x-small {
            height: 10px;
            width: 10px;
        }
    }
}