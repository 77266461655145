.toast-container {
    position: fixed;
    z-index: 99999;
    max-width: 300px;
    top: 80px !important;
    right: 20px !important;
}

.toast {
    box-shadow: 0px 5px 24px 0px rgba(0,0,0,0.15);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    position: relative;
    right: 0;
    color: #999;
    border-left: 5px solid #999;
    background: white;
    padding: 20px;
    padding-left: 45px;
    margin-bottom: 15px;
    border-radius: 4px;
    transition: .3s all ease;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
    }
    
    &--creating {
        transition: .4s all ease-in;
        transform: translate3d(0, 0, 0);
        right: -300px;
        opacity: 0;
    }
    
    &--closing {
        transition: .4s all ease-in;
        transform: translate3d(0, 0, 0);
        right: -300px;
        opacity: 0;
    }
    
    &--success {
        border-color: rgb(28, 184, 65);

        &:before {
            background-image: url('/icons/success.svg');
        }
    }

    &--info {
        border-color: #2f8ee6;

        &:before {
            background-image: url('/icons/info.svg');
        }
    }

    &--error {
        border-color: rgb(215, 92, 74);

        &:before {
            background-image: url('/icons/error.svg');
        }
    }

    &--warning {
        border-color: rgb(223, 117, 20);

        &:before {
            background-image: url('/icons/warning.svg');
        }
    }
}