.threedotloader__dots {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  .threedotloader__dot {
    width: 8px;
    height: 8px;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation: dotanim 1.6s infinite ease-in-out;
            animation: dotanim 1.6s infinite ease-in-out;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    transition: background-color .4s ease-in-out;
  }
  
  .threedotloader__dot:nth-child(2) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  
  .threedotloader__dot:nth-child(3) {
    -webkit-animation-delay: .4s;
            animation-delay: .4s;
  }
  
  @-webkit-keyframes dotanim {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    90% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  
  @keyframes dotanim {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    90% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }